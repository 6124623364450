<template>
  <transition name="toast-fade">
    <div v-if="visible" class="toast">{{ message }}</div>
  </transition>
</template>

<script>
export default {
  name: 'CustomToast',
  data() {
    return {
      visible: false,
      message: '',
      timeout: null // 用于存储定时器
    };
  },
  methods: {
    show(message, duration = 2000) {
      this.message = message;
      this.visible = true;

      // 清除之前的定时器
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      // 设置新的定时器
      this.timeout = setTimeout(() => {
        this.visible = false;
      }, duration);
    }
  }
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  font-size: 32px;
  z-index: 1000;
  text-align: center; /* 使文字居中 */
  display: flex;
  align-items: center; /* 使文字垂直居中 */
  justify-content: center; /* 使文字水平居中 */
  min-width: 200px; /* 设置最小宽度以确保文字居中效果 */
  min-height: 50px; /* 设置最小高度以确保文字居中效果 */
}

.toast-fade-enter-active, .toast-fade-leave-active {
  transition: opacity 0.5s;
}

.toast-fade-enter, .toast-fade-leave-to {
  opacity: 0;
}
</style>
