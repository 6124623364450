import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "amfe-flexible";
import 'vant/lib/index.css';  // 引入 Vant 样式
import { getAppid } from '@/utils/common';  // 假设 getAppid 是获取 appid 的方法


function setFavicon(href) {
    let link = document.querySelector("link[rel*='icon']");
    if (!link) {
        link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = href;
}

function updateFavicon() {
    const appid = getAppid();
    let faviconPath = '/favicon1.ico';
    if (appid == 2) {
        faviconPath = '/favicon2.ico';
    }else if(appid == 3){
        faviconPath = '/favicon3.ico';
    }else if(appid == 4){
        faviconPath = '/favicon4.ico';
    }
    setFavicon(faviconPath);
}

// 更新 favicon
updateFavicon();

import { Loading, Overlay, Field, Button } from 'vant';
const app = createApp(App);
const components = [Loading, Overlay, Field, Button];

components.forEach(component => {
    app.use(component);
});

import CustomToast from './components/CustomToast.vue';
const toastInstance = createApp(CustomToast).mount(document.createElement('div'));
document.body.appendChild(toastInstance.$el);

app.config.globalProperties.$toast = toastInstance.show;

app.use(router).mount('#app');




